// NOMENSA footer.scss
// REPLACE ./assets WITH /assets
.footer-top {
  background-color: black;
  color: white;
}

.footer-bottom {
  background-color: #ffffff;
  width: 100%;
  .footer-bottom-info {
    p {
      font-family: $primary-font;
      font-size: 13px;
      color: #000000;
    }
    @include respond-above(md) {
      width: 40%;
    }
  }
  .footer-bottom-logos {
    @include respond-above(md) {
      width: 40%;
      margin-left: 17%;
    }
  }
}

.footer-links {
  color: $gray-120;

  h5 {
    font-family: $secondary-font-semi-bold;
  }

  a {
    color: $gray-120;
    display: block;

    &:hover {
      color: $gray-120;
    }
  }

  .footer-link {
    font-family: $primary-font;
  }

  .logo-col {
    @include respond-below(sm) {
      padding: 1rem;
    }
    a {
      color: $gray-120;
      display: block;
      text-decoration: none;

      &:hover {
        color: $gray-120;
      }
    }
  }
  &.footer-links-social {
    background-position: left 82%;
    background-repeat: no-repeat;
    height: 100%;

    @include respond-above(md) {
      background-position: left 100% bottom 15%;
    }
  }
}

.logo-text {
  color: $gray-120;
  font-family: $primary-font;
  font-size: 0.8rem;
  padding-top: 30px;
}

.footer-bottom {
  background-color: #ffffff;
  width: 100%;
  .footer-bottom-info {
    p {
      font-family: $primary-font;
      font-size: 13px;
      color: #000000;
    }
    @include respond-above(md) {
      width: 40%;
    }
  }
  .footer-bottom-logos {
    @include respond-above(md) {
      width: 40%;
      margin-left: 17%;
    }
  }
}

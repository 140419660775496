.styled-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &.checkbox-small {
      --checkbox-size: 1em;
    }

    &:not(.checkbox-small) {
        --checkbox-size: 1.6em;
    }

    div {
      width: var(--checkbox-size);
      min-width: var(--checkbox-size);
      height: var(--checkbox-size);
      position: relative;

      input[type="checkbox"] {
        position: relative;
        appearance: none;
        width: 100% !important;
        height: 100% !important;
        border: 0.1em solid #000;
        border-radius: 0.25em;
        margin: 0;
        outline: none;
        cursor: pointer;
        transition: all 250ms cubic-bezier(0.1, 0.75, 0.5, 1);

        &.is-invalid {
          border-color: $form-invalid-border-color !important;
        }

        &:focus {
          box-shadow: 0 0 0 0.1em #000;
        }
      }
    }
    
    > label {
      width: fit-content;
      margin: 0;
      line-height: normal;

      &.hover-override + div input[type="checkbox"]:not(:checked) {
        &:hover {
          background: #f8f8f8;
          border-color: #666;
        }
      }
    }
  }

@import "../common/questions";

.action-buttons-popup-container {
  position: absolute;
  height: 100%;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 3px solid $secondary;
  padding-left: 1.5rem;
  padding-right: 2rem;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  z-index: 2;
  .question-actions-link {
    margin-top: 2px; // Just to vertically center the links nicely
    color: $secondary;
    .btn {
      margin-left: 7px;
      margin-right: 7px;
    }
  }

  &:has(> input + a) {
    flex-direction: row;

    input {
      width: auto;
    }
  }

  a {
    flex-grow: 1;
    align-self: center;
  }

  input {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: all;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
  }

  & ~ .duplicate-and-edit {
    position: absolute;
    right: 30px;
    padding: 24px 24px 0;
    top: 24px;
    text-align: center;
    background: white;
    border: 1px solid $gray-118;
    border-radius: 8px;
    border-top-left-radius: 0;
    font-size: small;
    z-index: 1;
  }

  &.double-height {
    background: #fff;
    text-align: center;
  }
}

// Validation response
.validation-response-panel {
  text-align: left;
  border-top: solid 1px $gray-107;
  border-bottom: solid 1px $gray-107;
  &.correct, &.almost {
    margin-left: -3rem;
    margin-right: -3rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    @include media-breakpoint-down(md) {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
    }
  }
  &.correct {
    background: $cyan-600;
    color: $cs-black;
    border-top: none;
    margin-bottom: -3rem;
  }
  &.almost {
    background: $yellow-400;
    color: $cs-black;
    border-top: none;
  }
}

// Quick questions
.quick-question-title {
  display: flex;
  justify-content: center;
}

// Cloze questions
.cloze-item {
  background: $yellow-300 !important;
  color: $cs-black;
}

.cloze-drop-zone, .cloze-dropdown {
  &.border {
    border-style: dashed !important; // necessary because Bootstrap has no border style utilities
  }
}

.cloze-dropdown {
    &:not(.empty) {
        // Remove button styling when full
        border: 0 !important;
        border-radius: 0;
    }
}

// Symbolic questions
.symbolic-question,
.equality-page {
  .eqn-editor-preview {
    border: solid 1px $cs-black;
    font-size: 1.5rem;
    line-height: 1.625;
  }
  // horrible hack to reduce size, fighting an !important in cs/forms.scss
  .eqn-editor-input input.form-control {
    line-height: 2rem !important;
  }
}

// Confidence questions
.quick-q-alert {
  background-color: $cs-cultured;
  margin-top: 1rem;
}

.feedback-zone {
  &.nq-feedback {
    .feedback-box {
      right: 90px;
    }
  }
}

.related-questions {
  .related-q-icon {
    display: inline-block;
    height: auto;
    width: 2rem;
  }
}

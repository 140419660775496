@mixin reduced-motion {
  // if reduced motion is enabled in the browser or the app, apply these reduced-motion styles e.g. instant transitions
  @media (prefers-reduced-motion: reduce) {
    @content;
  }

  @at-root main[data-reduced-motion="true"] & {
    @content;
  }
}

@mixin not-reduced-motion {
  // if reduced motion is not enabled in the browser or the app, don't apply these motion styles e.g. hover scale effects
  @media (prefers-reduced-motion: no-preference) {
    @at-root main[data-reduced-motion="false"] & {
      @content;
    }
  }
}

@mixin reduced-motion-compliant-animation {
  @content;
  
  // https://www.a11yproject.com/posts/understanding-vestibular-disorders/#what-should-you-consider%3F
  @include reduced-motion {
    transition-duration: 0.001s !important;
    animation-duration: 0.001s !important;
  }
}

.collapsing { // a bootstrap class used in accordions
  @include reduced-motion-compliant-animation;
}

#main {
  // default values for all accessibility variables
  --focus-outline: 2px solid #000; // TODO use this
}

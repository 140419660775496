@import "../common/accordions";

.stage-label-higher, .stage-label-core, .stage-label-advanced, .stage-label-all {
  color: black;
  font-weight: 600;
  text-wrap: none;
  font-size: 1rem !important;
  line-height: 1.15rem;
}

.stage-label-higher {
  background-color: $higher-colour;
}

.stage-label-core {
  background-color: $core-colour;
}

.stage-label-advanced {
  background-color: $advanced-colour;
}

.stage-label-all {
  background-color: $all-stages-colour;
}

.accordion-icon-report {
  background-image: url(/assets/cs/icons/flag-black.svg);
}

.accordion button.accordion-header {
  span, button, button:hover {
    color: black !important;

    &.stage-label-higher, &.de-emphasised > span {
      color: white !important;
    }
  }

  &::after {
    width: 30px;
    height: 10px;
    background-position: right;
    margin: auto 1rem auto 0;
  }
}

button.accordion-header {
  &:after {
    content: "";
    background: url('/assets/common/icons/chevron_down.svg') no-repeat center center;
    background-size: contain;
    width: 12px;
    height: 12px;
    margin: auto 0 auto 1rem;
  }

  &.active {
    border-bottom: 1px solid $shadow-08 !important;
  }
}

.accordion {
  background: $cs-white;
  border-top: 1px solid $cs-cultured;

  margin-top: 2px;
  &:first-of-type {
    margin-top: 0;
    border-top: 0;
  }
  &:last-of-type {
    margin-bottom: 1rem;
  }

  .content-value h4 {
    margin-bottom: 1rem;
  }
}

.list-group-links {
  border: 1px solid $gray-107;
  .question-link-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  .bg-success {
    background-color: #dfefd7 !important;
    a > span:first-of-type {
      border-color: #ddd;
    }
  }

  li {
    border-bottom: 1px solid $gray-107;

    &.list-group-category {
      a {
        color: $purple;
      }
    }

    a {
      display: flex;
      text-decoration: none;
      position: relative;
    }

    &:last-of-type {
      border-width: 0;
    }
  }
}

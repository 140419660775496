button.scroll-btn.scroll-btn {
  position: fixed;
  display: flex;
  opacity: 0;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 10px;
  box-shadow: 2px 2px 10px 0 #0004;
  background-color: #fff;
  border-radius: 50%;
  transition: opacity 0.1s linear;
  z-index: 101;

  &.is-sticky {
    opacity: 1;
  }
  
  > img {
    margin-top: -4px;
    height: 80%;
    width: 80%;
  }

  > i {
    mask-size: 20px;
  }
}

// NOMENSA breadcrumbs.scss
.bread {
  background-color: $white;
  box-shadow: 0 2px 30px 0 $shadow-08;
  @include respond-above(xs) {
    background-color: transparent;
    box-shadow: none;
  }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    background-color: transparent;
    margin: 0;
  }
}

@import "../common/list-groups.scss";

.list-group-links {
  border: 0;
  border-radius: 0;
  .list-group-item {
    .question-link-title {
      @extend .font-size-1-25;
      @extend .fw-semi-bold;
    }
    border-color: $cs-list-outline-gray;
    border-left: 0;
    border-right: 0;
  }
  .list-caret {
    position: relative;
    text-align: center;
    width: 50px;
    min-width: 50px !important;
    border-left: 1px solid $cs-list-outline-gray;
  }

  li a {
    font-size: 1.25rem;

    > span:first-of-type {
      display: inline-block;
      border-right: 1px solid $gray-107;
      margin-right: 1rem;
      padding-right: 1rem;
      text-align: center;
      min-width: 3.4rem;
    }
  }
}

/// Replace `$search` with `$replace` in `$string`
/// @author Kitty Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/// @author Kitty Giraudel
@function str-repeat($string, $times) {
  $result: "";

  @for $i from 1 through $times {
    $result: $result + $string;
  }

  @return $result;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.h-min-content {
  height: min-content !important;
}

.h-max-content {
  height: max-content !important;
}

.w-min-content {
  width: min-content !important;
}

.w-fit-content {
  width: fit-content !important;
}

.w-max-content {
  width: max-content !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-1 {
  border-radius: 0.25rem !important;
}

.border-radius-2 {
  border-radius: 0.5rem !important;
}

.border-radius-3 {
  border-radius: 1rem !important;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.white-space-pre {
  white-space: pre !important;
}

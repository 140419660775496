.quiz-list {
  font-family: $secondary-font-medium;
  background-color: $white;
  border: 1px solid $gray-107;
  border-bottom: 1px solid $gray-107;
  font-size: 1.25rem;

  > li {
    display: flex;
    border-bottom: 1px solid $gray-107;

    .quiz-list-separator {
      border-left: 1px solid $gray-107;
      &:after {
        @include pseudo-element;
        background-image: url('/assets/common/icons/chevron_right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        height: 100%;
        right: 10px;
        top: 0;
        bottom: 0;
        width: 9px;
      }
    }

    .set-quiz-button-md {
      min-width: 120px;
      height: 45px;
    }

    .set-quiz-button-sm {
      min-width: 90px;
    }
  }
}

.my-quizzes-card {
  min-height: 100%;
}

.card-grid-xs * {
  flex-basis: 100%;
}

.card-grid-sm *, .card-grid-md * {
  flex-basis: 50%;
}

.card-grid-lg *, .card-grid-xl * {
  flex-basis: 33.333333%;
}

.quiz-marks-table td {
  padding-left: 16px;
}

.quiz-student-menu {
  position: relative;
  width: 100%;
  .quiz-student-name {
    margin-right: 30px;
    padding-left: 10px;
    padding-right: 4px;
    word-wrap: anywhere;
  }
  .quiz-student-menu-icon {
    position: absolute;
    top: calc(50% - 1px);
    right: 5px;
    transform: translateY(-50%);
  }
}
.quiz-student-menu-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.quizFeedbackModeSpinner {
  vertical-align: middle;
}

.quiz-filter-date-input {
  width: min-content;
}

.quiz-filter-date-span {
  font-size: 1.111rem;
}

.set-quiz-table-inner {
  background: $gray-103;
  table-layout: fixed;

  tbody tr td {
    padding: 0.25rem;
    vertical-align: middle;
  }

  thead tr th {
    user-select: none;
    vertical-align: middle;
    
    .sort {
      display: flex;
      flex-direction: column;
      font-size: 0.5rem;
      margin-left: 4px;
      margin-top: 3px;

      span {
        color: #bbb;
      }

      .up {
        margin-bottom: -4px;
      }

      .active {
        color: #333;
      }
    }
  }

  .actions-header-sm {
    width: 200px;
  }

  .btn-collapsed {
    max-width: 80px;
  }

  .btn-full {
    min-width: 120px;
  }
}

.set-quiz-table-title {
  height: 86px;
  font-size: 1.25rem;
  font-family: $secondary-font-medium;
}

.set-quiz-table-group-hex {
  font-size: 12pt;
  line-height: 110%;
  font-weight: bold;
  z-index: 10;
  top: 28px;
  left: 0.7rem;
  color: #fff;
  text-align: center;
  strong {
    display: block;
    font-size: 20pt;
    font-weight: 600;
    padding-bottom: 4px;
    margin-top: -6px;
  }
}

.set-quiz-table-dropdown {
  cursor: pointer;
  user-select: none;
  
  .dropdown-arrow {
    content: "";
    background-image: url('/assets/common/icons/chevron_down.svg');
    background-repeat: no-repeat;
    background-position: left center;
    width: 20px;
    height: 10px;
    min-width: 1rem;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: auto;

    &.active {
        background-image: url('/assets/common/icons/chevron_up.svg');
    }
  }

  button.btn.btn {
    min-width: max-content;
  }
}

.copy-test-filter-link {
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 5px;
  top: 2px;
  right: 20px;
  background: url("/assets/action-copy.svg") no-repeat center content-box;
  background-size: cover;
  filter: invert(0.5);
  transform: scaleY(-1);

  &.clicked {
    background: url("/assets/action-done.svg") no-repeat center content-box;
    transform: none;
  }
}

.my-quizzes-table {
  @extend .my-gameboard-table;
  min-width: 694px;
  thead > tr {
    @extend .my-gameboard-table-header;
    > th {
      padding: 0.5rem 1rem;
    }
    > th:not(:last-child) {
      cursor: pointer;
    }
  }
  tbody > tr {
    @include td-link-padding(0.8rem 1rem);

    &:not(.overdue) {
      cursor: pointer;
    }
    &.overdue {
      background: rgba($gray-160, 0.09);
    }
    &.completed {
      background: rgba(map-get($theme-colors, "success"), 0.09);
    }

    > td {
      background: transparent;
    }
  }
}

// ISAAC
.content-summary-link .content-summary-link-title {
  background: none;
  border: 0;
  .text-theme {
    font-family: $secondary-font-semi-bold;
  }
}

.new-tab {
  height: 24px;
  width: 24px;
}

.content-summary-link {
  a {
    text-decoration: none;
  }
  &.de-emphasised {
    background: rgba($gray-160, 0.09);
  }
}

.ratio-16x9 {
  @include aspect-ratio(16, 9);
}

.ratio-5x3 {
  @include aspect-ratio(5, 3);
}

.content-video-container {
  position: relative;
  margin-bottom: 1rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin-bottom: 2rem;
  }
}

.email-preview-frame {
  width:100%;
  height:800px;
  border:solid thin #e3e3e3;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-transform {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.isaac-checkbox {
  text-align: center;
  transform: scale(1.5);
}

.table-title {
  text-align: center;
}

// NOMENSA login.scss
button.btn.btn-link.login-b,
.login a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }
}

.login {
  display: inline-block;

  a {
    display: block;
  }
}

// NOMENSA signup.scss
.signup {
  display: none;

  @include respond-above(sm) {
    display: block;
  }

  a {
    color: $black;
    font-family: $primary-font;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    display: block;

    &:hover {
      @include respond-above(sm) {
        text-decoration: underline;
      }
    }
  }
}

// ISAAC after final Nomensa delivery we can reduce duplication
.login a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }

  span {
    border: 0;
    display: block;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    clip: initial;
    height: initial;
    position: relative;
    width: initial;
  }

}

.my-account a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  position: initial !important;
  display: unset !important;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }

  span {
    border: 0;
    display: block;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    clip: initial;
    height: initial;
    position: relative;
    width: initial;
  }

}

.logout {
  display: none;

  @include respond-above(sm) {
    display: inline-block;
  }

  a {
    font-size: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;
    position: initial !important;
    display: unset !important;

    &:hover {
      @include respond-above(sm) {
        text-decoration: underline;
      }
    }
  }
}

.not-mobile {
  @include respond-below(sm) {
    display: none !important;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.grab-cursor {
  cursor: grab;
}

@each $name, $size in $element-sizes {
  .w-#{$name} {
    width: $size !important;
  }

  .h-#{$name} {
    height: $size !important;
  }
}

.overflow-visible {
  overflow: visible !important;
}

iframe.email-html {
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  height: content-box;
  background-color: #e9ecef;
  opacity: 1;
}

// OLD NOMENSA TO ALTER INTO ISAAC
//a, button {
//  &.btn.btn-link.login-b {
//    color: $black;
//    font-family: $primary-font;
//    font-weight: 400;
//    text-decoration: underline;
//
//    &::before {
//      @include pseudo-element();
//      background-image: url('/assets/avatar.svg');
//      background-repeat: no-repeat;
//      background-size: contain;
//      height: 24px;
//      width: 24px;
//
//      @include respond-above(sm) {
//        display: none;
//      }
//    }
//
//    span {
//      border: 0;
//      clip: rect(0, 0, 0, 0);
//      display: block;
//      height: 1px;
//      overflow: hidden;
//      padding: 0;
//      position: absolute;
//      white-space: nowrap;
//      width: 1px;
//
//
//      @include respond-above(sm) {
//        clip: initial;
//        height: initial;
//        position: relative;
//        width: initial;
//      }
//    }
//  }
//}

.collapsible-head {
  margin-left: 0;
  margin-right: 0;
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: $gray-107;

  img {
    transition: transform 0.1s ease;
  }
}

.collapsible-body {
  @include reduced-motion-compliant-animation {
    transition: max-height 0.3s ease-in-out, height 0.3s ease-in-out;
  }
  // https://stackoverflow.com/q/6421966; x-overflow must be visible (for e.g. box shadows), y-overflow would preferably be entirely hidden but can't be.
  overflow: visible clip;
  // height must be animated alongside max-height to prevent jumping if the inner content changes height during the animation
  max-height: 0;
}

@mixin pill-tag($text-color: black, $bg-color: var(--subject-color-50, white), $border-color: null) {
  width: max-content;
  padding: 0 0.4em;
  align-self: center;
  font-size: small;
  text-decoration: none;
  color: $text-color;
  background-color: $bg-color;
  border-radius: $border-radius-pill;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  &::after {
    display: none;
  }

  @if $border-color {
    border: 1px solid $border-color;
  }

  &:is(a):hover {
    color: color-mix(in srgb, var(--pill-text-color, black), black 20%);
    background-color: color-mix(in hsl, var(--pill-bg-color, var(--subject-color-50)), black 5%);
  }
}

.superseded-tag.superseded-tag {
  @include pill-tag($gray-160, white, $gray-194)
}

// mirror of bootstrap's .dropdown-divider (+ equiv for the y-axis), using always-defined props

.section-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  opacity: 1;
  width: 100%;
}

.section-divider-y {
  width: 0;
  margin: 0 0.5rem;
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.section-divider-bold {
  @extend .section-divider;
  border-top: 2px solid rgba(0, 0, 0, 0.20);
}

.hoverable-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline dotted;
  &:hover .popup {
    display: block;
  }
}

.tab-picker {
  cursor: pointer;
  border-left: solid 2px transparent;

  .badge.rounded-pill {
    background-color: white;
    color: var(--bs-body-color-rgb);
  }

  background-size: 205% 100%;
  background-image: linear-gradient(90deg, var(--subject-color-100) 50%, transparent 50%);
  background-position: 100% 0%;
  transition: background-position 0.3s ease-in-out;
  
  &:has(> input:checked) {
    border-left: solid 2px var(--sidebar-tab-active);
    background-position: 0% 0%;
    
    .badge.rounded-pill {
      background-color: var(--sidebar-tab-active);
      color: white;
    }
  }

  &:hover:not(:has(> input:checked, > input:disabled)) {
    border-left: solid 2px var(--sidebar-tab-hover);
  }

  &:has(> input:disabled) {
    color: var(--sidebar-tab-disabled);
    cursor: not-allowed;
  }

  &:has(> input:focus-visible) {
    // TODO: find-replace all instances of this with some SCSS vars defined in isaac.scss
    box-shadow: 0 0 0 2pt black !important;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  > input {
    @extend .visually-hidden;
  }
}
